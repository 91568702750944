/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-content-relation-update .header {
  /* display: flex; */
  /* flex-direction: column; */
  flex: 0 0 125px;
  /* align-items: center; */
  margin-bottom: 10px;
}
.altai-theme-admin .altai-content-relation-update .header label {
  margin: 0;
  margin-right: 10px;
  /*padding-top: 16px;*/
}
.altai-theme-admin .altai-content-relation-update .header button {
  margin-right: 5px;
}
.altai-theme-admin .altai-content-relation-update .drag-handle {
  display: inline-block;
  cursor: move;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper {
  display: flex;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper .altai-content-relation-update-item {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper .altai-content-relation-update-item .close-button {
  margin-top: 10px;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper .altai-content-relation-update-item .content-rep {
  display: flex;
  flex: 1;
  margin-right: 5px;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper.multiple,
.altai-theme-admin .altai-content-relation-update .item-wrapper {
  /* border: 1px solid #e0e0e0; */
  padding: 5px 5px 5px 5px;
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}
.altai-theme-admin .altai-content-relation-update .item-wrapper-multiselect {
  background-color: transparent;
  align-items: center;
}
.altai-theme-admin .fullWidth {
  width: 100%;
}
